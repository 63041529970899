exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuellt-index-js": () => import("./../../../src/pages/aktuellt/index.js" /* webpackChunkName: "component---src-pages-aktuellt-index-js" */),
  "component---src-pages-aktuellt-wp-post-slug-js": () => import("./../../../src/pages/aktuellt/{WpPost.slug}.js" /* webpackChunkName: "component---src-pages-aktuellt-wp-post-slug-js" */),
  "component---src-pages-att-studera-index-js": () => import("./../../../src/pages/att-studera/index.js" /* webpackChunkName: "component---src-pages-att-studera-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konferens-js": () => import("./../../../src/pages/konferens.js" /* webpackChunkName: "component---src-pages-konferens-js" */),
  "component---src-pages-kurser-allmanna-kurser-js": () => import("./../../../src/pages/kurser/allmanna-kurser.js" /* webpackChunkName: "component---src-pages-kurser-allmanna-kurser-js" */),
  "component---src-pages-tack-js": () => import("./../../../src/pages/tack.js" /* webpackChunkName: "component---src-pages-tack-js" */),
  "component---src-pages-utbildningar-index-js": () => import("./../../../src/pages/utbildningar/index.js" /* webpackChunkName: "component---src-pages-utbildningar-index-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-kurs-js": () => import("./../../../src/templates/kurs.js" /* webpackChunkName: "component---src-templates-kurs-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

